var exports = {};
exports.__esModule = true;
var checkedPkgs = {};

var checkStyles = function checkStyles() {};

exports.checkStyles = checkStyles;

var wrapEvent = exports.wrapEvent = function wrapEvent(theirHandler, ourHandler) {
  return function (event) {
    theirHandler && theirHandler(event);

    if (!event.defaultPrevented) {
      return ourHandler(event);
    }
  };
};

var assignRef = exports.assignRef = function assignRef(ref, value) {
  if (ref == null) return;

  if (typeof ref === "function") {
    ref(value);
  } else {
    try {
      ref.current = value;
    } catch (error) {
      throw new Error("Cannot assign value \"" + value + "\" to ref \"" + ref + "\"");
    }
  }
};

export default exports;
export const __esModule = exports.__esModule;
const _checkStyles = exports.checkStyles,
      _wrapEvent = exports.wrapEvent,
      _assignRef = exports.assignRef;
export { _checkStyles as checkStyles, _wrapEvent as wrapEvent, _assignRef as assignRef };